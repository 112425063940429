<template>
  <div class="manage">
    <el-container>
      <el-aside width="150px" class="menus">
        <div class="menu-item" :class="{'menu-item-active': active == 'AddCustomer'}" @click="change('AddCustomer')">
          <div class="text">新建客户</div>
          <div class="mask"></div>
        </div>
        <!-- <div class="menu-item" :class="{'menu-item-active': active == 'ProCustomer'}" @click="change('ProCustomer')">
          <div class="text">潜在客户</div>
          <div class="mask"></div>
        </div> -->
        <div class="menu-item" :class="{'menu-item-active': active == 'Customers'}" @click="change('Customers')">
          <div class="text">全部客户</div>
          <div class="mask"></div>
        </div>
        <div class="menu-item" :class="{'menu-item-active': active == 'AllOrders'}" @click="change('AllOrders')">
          <div class="text">订单列表</div>
          <div class="mask"></div>
        </div>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const active = ref('AddCustomer')

const change = page => {
  active.value = page
  router.push({ name: page })
}

</script>
<style lang="less" scoped>
.manage {
  height: 100%;
  width: 100%;
}
.menus {
  box-shadow: 5px 0 5px -5px #ccc;
  height: calc(100vh - 120px);
  .menu-item {
    height: 60px;
    position: relative;
    cursor: pointer;
    .text {
      position: absolute;
      left: 20px;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
    }
  }
  .menu-item-active {
    .text {
      color: #3aa20f;
    }
    .mask {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: #3aa20f;
      opacity: 0.1;
    }
    &::after {
      position: absolute;
      display: inline-block;
      content: '';
      left: 0;
      top: 0;
      height: 100%;
      width: 3px;
      background-color: #3aa20f;
    }
  }
}
</style>
